/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class Service {

    /**
     * @param rowsPerPage
     * @param page
     * @param order
     * @param filters
     * @param orderBy
     * @param searchText
     * @returns any
     * @throws ApiError
     */
    public static async getAllAdmins(
        rowsPerPage: number,
        page: number,
        order: 'ASC' | 'DESC' = 'ASC',
        filters?: Array<string>,
        orderBy?: string,
        searchText?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/admins`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'order': order,
                'filters': filters,
                'orderBy': orderBy,
                'searchText': searchText,
            },
        });
        return result.body;
    }

    /**
     * @param rowsPerPage
     * @param page
     * @param order
     * @param filters
     * @param orderBy
     * @param searchText
     * @returns any
     * @throws ApiError
     */
    public static async getAllMeetings(
        rowsPerPage: number,
        page: number,
        order: 'ASC' | 'DESC' = 'ASC',
        filters?: Array<string>,
        orderBy?: string,
        searchText?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/meetings`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'order': order,
                'filters': filters,
                'orderBy': orderBy,
                'searchText': searchText,
            },
        });
        return result.body;
    }

    /**
     * @param rowsPerPage
     * @param page
     * @param order
     * @param filters
     * @param orderBy
     * @param searchText
     * @returns any
     * @throws ApiError
     */
    public static async getAllOrganizations(
        rowsPerPage: number,
        page: number,
        order: 'ASC' | 'DESC' = 'ASC',
        filters?: Array<string>,
        orderBy?: string,
        searchText?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/organizations`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'order': order,
                'filters': filters,
                'orderBy': orderBy,
                'searchText': searchText,
            },
        });
        return result.body;
    }

}