import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Alert from '@mui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ReactComponent as PasswordLock } from 'assets/icons/lock.svg';
import { Service as AccountService } from 'clients/AccountService';
import { useExtractErrorMessage } from 'hooks/language/useExtractErrorMessage';
import { PasswordFieldsProps } from '.';

import { PasswordTextField } from 'components/TextFields';
import {
	WDialogActions,
	WDialogContent,
	WDialogTitle,
	PasswordLockWrapper,
	PasswordTypography,
	ChangePasswordButton,
	WEdit,
	SettingsDialog,
} from './Settings.styles';

export interface SettingsProps {
	open: boolean;
	onClose: any;
}

export type SettingsDialogContent = 'settings' | 'changePassword';

export default function Settings({ open, onClose }: SettingsProps) {
	const { t } = useTranslation('components', { keyPrefix: 'settings' });
	const [settingsDialogContent, setSettingsDialogContent] = useState<SettingsDialogContent>(
		'settings',
	);

	return (
		<SettingsDialog open={open} onClose={onClose}>
			<WDialogActions>
				<IconButton
					disabled={settingsDialogContent === 'settings'}
					onClick={() => {
						if (settingsDialogContent === 'changePassword') {
							setSettingsDialogContent('settings');
							return;
						}
					}}
				>
					<ArrowBackIcon />
				</IconButton>
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</WDialogActions>
			<WDialogTitle>
				<Typography variant={'h4'}>
					{settingsDialogContent === 'settings' && t('title')}
					{settingsDialogContent === 'changePassword' && t('changePassword.dialog.title')}
				</Typography>
			</WDialogTitle>
			<WDialogContent
				className={classNames({ 'internal-gaps': settingsDialogContent === 'changePassword' })}
			>
				{settingsDialogContent === 'settings' && (
					<>
						<PasswordTypography>
							<PasswordLockWrapper>
								<PasswordLock />
							</PasswordLockWrapper>
							{t('changePassword.password')}
						</PasswordTypography>
						<ChangePasswordButton onClick={() => setSettingsDialogContent('changePassword')}>
							{t('changePassword.changePassword')}
							<WEdit />
						</ChangePasswordButton>
					</>
				)}
				{settingsDialogContent === 'changePassword' && <ChangePassword />}
			</WDialogContent>
		</SettingsDialog>
	);
}

export function ChangePassword() {
	const { t } = useTranslation('components', { keyPrefix: 'settings' });
	const [passwordState, setPasswordState] = useState<PasswordFieldsProps>({
		oldPassword: '',
		newPassword: '',
		repeatPassword: '',
	});
	const [isDisable, setIsDisable] = useState(true);

	const passwordsNotMatch =
		!!passwordState.newPassword &&
		!!passwordState.repeatPassword &&
		passwordState.newPassword !== passwordState.repeatPassword;

	useEffect(() => {
		setIsDisable(
			!!passwordState.oldPassword &&
				!!passwordState.newPassword &&
				!!passwordState.repeatPassword &&
				passwordState.newPassword === passwordState.repeatPassword &&
				passwordState.newPassword.length > 8,
		);
	}, [passwordState]);

	const { mutate: resetPassword, error, isLoading, isSuccess } = useMutation<void, any>(
		async () => {
			if (passwordState.newPassword !== passwordState.repeatPassword) {
				throw new Error('Passwords do not match');
			}

			return AccountService.resetPassword({
				oldPassword: passwordState.oldPassword,
				newPassword: passwordState.newPassword,
			});
		},
	);

	return (
		<>
			{isLoading && <LinearProgress />}
			<PasswordTextField
				label={t('changePassword.dialog.labels.currentPassword')}
				onChange={e =>
					setPasswordState(prev => ({
						...prev,
						oldPassword: e.target.value,
					}))
				}
			/>
			<PasswordTextField
				label={t('changePassword.dialog.labels.newPassword')}
				onChange={e => setPasswordState(prev => ({ ...prev, newPassword: e.target.value }))}
				error={passwordState.newPassword.length >= 1 && passwordState.newPassword.length < 8}
				helperText={
					passwordState.newPassword.length >= 1 && passwordState.newPassword.length < 8
						? t('changePassword.dialog.tooShort')
						: null
				}
				inputProps={{ minLength: 8, maxLength: 32 }}
			/>
			<PasswordTextField
				label={t('changePassword.dialog.labels.repeatNewPassword')}
				onChange={e => setPasswordState(prev => ({ ...prev, repeatPassword: e.target.value }))}
				error={passwordsNotMatch}
				helperText={passwordsNotMatch ? t('changePassword.dialog.helperText') : null}
				inputProps={{ minLength: 8, maxLength: 32 }}
			/>
			<PasswordError error={error} />
			{isSuccess && <Alert severity={'info'}>{t('changePassword.dialog.passwordChanged')}</Alert>}

			<Button fullWidth onClick={() => resetPassword()} disabled={!isDisable || isLoading}>
				{t('changePassword.dialog.confirmText')}
			</Button>
		</>
	);
}

export function isPasswordFormatError(error?: any) {
	return error?.body?.id === 'WrongPasswordFormatError';
}

export function PasswordError({ error }: { error?: any }) {
	const extractErrorMessage = useExtractErrorMessage();

	return (
		error && (
			<Alert severity={'error'}>
				{isPasswordFormatError(error) ? (
					<Trans i18nKey={'components:passwordError'} />
				) : (
					extractErrorMessage(error)
				)}
			</Alert>
		)
	);
}
