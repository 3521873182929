/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MeetingType {
    VIDEO_CONFERENCE = 'VideoConference',
    EXTENDED_VOTE_WITH_MEETING = 'ExtendedVoteWithMeeting',
    VOTING_ONLY = 'VotingOnly',
    LIVE_ROOM_ONLY_MEETING = 'LiveRoomOnlyMeeting',
    VOICE_BROADCASTING = 'VoiceBroadcasting',
}