// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type from '@mui/lab/themeAugmentation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import typeGrid from '@mui/x-data-grid/themeAugmentation';

import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import merge from 'lodash/merge';

import { shadows } from 'themes/shadows';
import { DataGridProps } from '@mui/x-data-grid';
import {
	MeetingStatus,
	PollDisplayResultsStatus,
	PollStatus,
	RoomStatusResponse,
} from 'clients/CoreService';

export interface Label {
	color: string;
	background: string;
	border?: string;
}

export const chartsColors = {
	primary: '#0A6672',
	secondary: '#FF5D00',
	tertiary: '#5CADB9',
	empty: '#F0F0F0',
};

export const votesColors = [
	'#0A6672',
	'#FF5D00',
	'#FFB547',
	'#7BC67E',
	'#579859',
	'#3A97E0',
	'#BD861B',
	'#ECDC4B',
	'#FEEFD1',
];

export const calloutProgressColors = ['#FFB547', '#7BC67E', '#579859', '#3A97E0', '#FEEFD1'];

const meetingStatuses: Record<MeetingStatus, string> = {
	[MeetingStatus.CREATED]: '#FFAE7F',
	[MeetingStatus.ACTIVATED]: '#FF5D00',

	[MeetingStatus.STARTING]: '#0EFFAE',
	[MeetingStatus.STARTED]: '#0EFFAE',
	[MeetingStatus.COMPLETED]: '#29747D',

	[MeetingStatus.CANCELED]: '#FEEFD1',
	[MeetingStatus.DELETED]: '#FEEFD1',
};

const roomStatuses: Record<RoomStatusResponse['status'], string> = {
	Online: '#00A779',
	Offline: '#BA1A0D',
};

const ballotStatuses: Record<PollStatus, Label> = {
	[PollStatus.CREATED]: { color: '#000000', background: '#F0F0F0' },
	[PollStatus.STARTED]: { color: '#FFFFFF', background: '#E5B652' },
	[PollStatus.ENDED]: { color: '#FFFFFF', background: '#00A779' },
};

const votesResultsVisibility: Record<PollDisplayResultsStatus, Label> = {
	[PollDisplayResultsStatus.HIDDEN]: { color: '#0A6672', background: '#F0F0F0', border: '#0A6672' },
	[PollDisplayResultsStatus.SHOW_GENERAL_RESULTS]: {
		color: '#FFFFFF',
		background: '#c8c8c8',
	},
	[PollDisplayResultsStatus.SHOW_CALCULATION_STAGES]: {
		color: '#FFFFFF',
		background: '#c8c8c8',
	},
};

export const colors = {
	strokes: '#B3B3B3',
	questionBG: '#E5E1D8',
	pollCardBG: '#f2f4f6',
	outBallotOption: '#828282',
	selectedOptionRankBg: '#E4EDEE',
	rankingBg: '#F3F7F8',
	emptyStar: 'rgba(66, 82, 110, 0.15)',
	lightPrimary: '#EEF4F5',
	voteCardHoverBg: '#E4EEF0',
	live: '#EB4200',
	voteCountdown: '#EB4200',
	activeVoteBg: '#FFF5F0',
	activeVoteBorder: '#BA830D',
	sidebarAvatarBackground: '#0A667218',
	meetingStatuses,
	roomStatuses,
	ballotStatuses,
	votesResultsVisibility,
};

const themeOptions: ThemeOptions = {
	palette: {
		primary: { main: '#0A6672', contrastText: '#ffffff' },
		secondary: { main: '#FF5D00', contrastText: '#ffffff' },
		background: { default: '#f4f5f7', paper: '#ffffff' },
		text: { primary: '#253858', secondary: '#68758B' },
		action: { active: '#6b778c' },
		success: { main: '#237680', contrastText: '#ffffff', light: '#EDF7ED' },
		warning: { main: '#FF7D34', contrastText: '#ffffff' },
		error: { main: '#FF5D01', contrastText: '#ffffff' },
		grey: {
			'50': '#BDBDBD',
			'300': '#E0E0E0',
			'500': '#D0D4DB',
			'700': '#DFDFDF',
			'800': '#F4F5F7',
		},
	},
	shadows: shadows,
	direction: 'ltr',
	shape: { borderRadius: '4px' },
	components: {
		MuiAvatar: { styleOverrides: { fallback: { height: '75%', width: '75%' } } },
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					padding: '0.5rem 2.5rem',
					minWidth: 'none',
				},
			},
			defaultProps: {
				variant: 'contained',
				color: 'primary',
			},
			variants: [
				{ props: { variant: 'text', color: 'secondary' }, style: { color: 'inherit' } }, // secondary text buttons have inherited color
				{ props: { variant: 'text' }, style: { padding: '0.5rem 1rem' } }, // text buttons have smaller default padding
			],
		},
		MuiLinearProgress: {
			styleOverrides: { root: { borderRadius: 3, overflow: 'hidden', height: '2px' } },
		},
		MuiListItemIcon: { styleOverrides: { root: { minWidth: 'auto', marginRight: '1rem' } } },
		MuiPaper: {
			styleOverrides: { root: { backgroundImage: 'none' }, rounded: { borderRadius: '0.5rem' } },
		},
		MuiSkeleton: { styleOverrides: { root: { backgroundColor: '#F7F8FA' } } },
		MuiOutlinedInput: {
			styleOverrides: {
				inputAdornedStart: { marginLeft: '0.25rem' },
				notchedOutline: { borderColor: colors.strokes },
			},
		},
		MuiDialog: { styleOverrides: { paper: { padding: '0.75rem 1.5rem 0.75rem' } } },
		MuiDialogTitle: { styleOverrides: { root: { padding: '24px 8px 0' } } },
		MuiDialogContent: { styleOverrides: { root: { padding: '24px 8px' } } },
		MuiDialogActions: { styleOverrides: { root: { padding: '0 0' } } },
		MuiDataGrid: {
			styleOverrides: {
				root: {
					padding: '1rem',
					boxShadow: shadows[3],
					border: 'none',
					background: '#fff',
					fontSize: '1rem',
				},
				checkboxInput: {
					color: '#0A6672',
				},
			},
			defaultProps: {
				sortingMode: 'server',
				paginationMode: 'server',
				disableVirtualization: true,
				autoPageSize: true,
				rowsPerPageOptions: [],
				checkboxSelection: true,
				disableSelectionOnClick: true,
			} as Partial<DataGridProps>,
		},
		MuiInputBase: { styleOverrides: { root: { '& fieldset': { borderColor: colors.strokes } } } },
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					background: 'transparent',
					padding: 0,
				},
			},
		},
	},
	typography: {
		fontFamily:
			'"SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
		button: { fontWeight: 600 },
		h1: { fontWeight: 600, fontSize: '3.5rem' },
		h2: { fontWeight: 600, fontSize: '3rem' },
		h3: { fontWeight: 600, fontSize: '2.25rem' },
		h4: { fontWeight: 600, fontSize: '2rem' },
		h5: { fontWeight: 600, fontSize: '1.5rem' },
		h6: { fontWeight: 600, fontSize: '1.125rem' },
		subtitle1: { fontSize: '0.875rem' }, // for Data-Grid cells
		subtitle2: { fontSize: '0.75rem' },
		overline: { fontWeight: 600 },
	},
};

export default responsiveFontSizes(createTheme(merge({ colors }, themeOptions)));
