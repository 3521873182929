/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ParticipantResponse } from '../models/ParticipantResponse';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * @param participantId
     * @returns ParticipantResponse
     * @throws ApiError
     */
    public static async sendNotification(
        participantId: string,
    ): Promise<ParticipantResponse> {
        const result = await __request({
            method: 'POST',
            path: `/notifications/participants/${participantId}/send`,
        });
        return result.body;
    }

}