import { SharedStateReturn, useSharedState } from 'hooks/state/useSharedState';
import { entities } from 'consts';
import { useParams } from 'react-router-dom';

const generateSessionId = (): string => {
	const dateNow = new Date().getTime();
	const randomString = Math.random().toFixed(20);
	return `${dateNow}-${randomString}`;
};

const SESSION_ID_KEY = 'SESSION_ID';

function getSessionIdFromStorage(): string {
	let storageSessionId = sessionStorage.getItem(SESSION_ID_KEY);

	if (!storageSessionId) {
		storageSessionId = generateSessionId();
		setSessionIdToStorage(storageSessionId);
	}

	return storageSessionId;
}

function setSessionIdToStorage(sessionId: string): void {
	return sessionStorage.setItem(SESSION_ID_KEY, sessionId);
}

export function useSessionId(): SharedStateReturn<string> {
	const params = useParams();

	const [sessionId, setSessionId] = useSharedState(
		entities.sessionId,
		params.sessionId || getSessionIdFromStorage(),
		setSessionIdToStorage,
	);

	return [sessionId, setSessionId];
}
