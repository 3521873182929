/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountData } from '../models/AccountData';
import type { AccountResponse } from '../models/AccountResponse';
import type { ForceUpdatePasswordRequest } from '../models/ForceUpdatePasswordRequest';
import type { ImportRequest } from '../models/ImportRequest';
import type { LoginHistoryResponse } from '../models/LoginHistoryResponse';
import type { LoginRequest } from '../models/LoginRequest';
import type { ResetPasswordNotificationLanguage } from '../models/ResetPasswordNotificationLanguage';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { SignupRequest } from '../models/SignupRequest';
import type { TokenPayloadRequest } from '../models/TokenPayloadRequest';
import type { TokenRequest } from '../models/TokenRequest';
import type { UpdateLoginRequest } from '../models/UpdateLoginRequest';
import type { UpdatePasswordRequest } from '../models/UpdatePasswordRequest';
import type { VerificationRequest } from '../models/VerificationRequest';
import type { VerificationType } from '../models/VerificationType';
import { request as __request } from '../core/request';

export class Service {

    /**
     * @param requestBody
     * @returns AccountResponse Created
     * @throws ApiError
     */
    public static async signUp(
        requestBody: SignupRequest,
    ): Promise<AccountResponse> {
        const result = await __request({
            method: 'POST',
            path: `/account/signup`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any Verified
     * @throws ApiError
     */
    public static async verify(
        requestBody: VerificationRequest,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/account/verification`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns AccountResponse Logged in
     * @throws ApiError
     */
    public static async login(
        requestBody: LoginRequest,
    ): Promise<AccountResponse> {
        const result = await __request({
            method: 'POST',
            path: `/account/login`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns AccountData Login has been refreshed
     * @throws ApiError
     */
    public static async updateLogin(
        requestBody: UpdateLoginRequest,
    ): Promise<AccountData> {
        const result = await __request({
            method: 'PUT',
            path: `/account/login`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any Logged out
     * @throws ApiError
     */
    public static async logout(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/account/logout`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns AccountResponse Access token has been refreshed
     * @throws ApiError
     */
    public static async token(
        requestBody: TokenRequest,
    ): Promise<AccountResponse> {
        const result = await __request({
            method: 'POST',
            path: `/account/token`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Generate new access and refresh tokens for account with custom payload.
     * @param requestBody
     * @returns AccountResponse
     * @throws ApiError
     */
    public static async setTokenPayload(
        requestBody: TokenPayloadRequest,
    ): Promise<AccountResponse> {
        const result = await __request({
            method: 'POST',
            path: `/account/token/payload`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param login
     * @param language
     * @returns any
     * @throws ApiError
     */
    public static async recoverPassword(
        login: string,
        language?: ResetPasswordNotificationLanguage,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/account/settings/password`,
            query: {
                'login': login,
                'language': language,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async resetPassword(
        requestBody: (ResetPasswordRequest | UpdatePasswordRequest | ForceUpdatePasswordRequest),
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/account/settings/password`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Import bulk of accounts. Works only if config.internalUsage.enabled == true.
     * @param requestBody
     * @returns AccountData
     * @throws ApiError
     */
    public static async import(
        requestBody: ImportRequest,
    ): Promise<Array<AccountData>> {
        const result = await __request({
            method: 'POST',
            path: `/account/import`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param login
     * @param type
     * @returns any
     * @throws ApiError
     */
    public static async resendVerification(
        login: string,
        type: VerificationType,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/account/codes`,
            query: {
                'login': login,
                'type': type,
            },
        });
        return result.body;
    }

    /**
     * @returns LoginHistoryResponse
     * @throws ApiError
     */
    public static async getLoginHistory(): Promise<LoginHistoryResponse> {
        const result = await __request({
            method: 'GET',
            path: `/account/login-history`,
        });
        return result.body;
    }

    /**
     * @returns AccountData
     * @throws ApiError
     */
    public static async getAccountData(): Promise<AccountData> {
        const result = await __request({
            method: 'GET',
            path: `/account`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async healthy(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/healthy`,
        });
        return result.body;
    }

}