import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import meeting from 'locales/en/meeting.json';
import chat from 'locales/en/pages/chat.json';
import components from 'locales/en/components.json';
import organizationsPage from 'locales/en/pages/adminConsole/organizationsPage.json';
import organizersPage from 'locales/en/pages/adminConsole/organizersPage.json';
import meetingsPage from 'locales/en/pages/adminConsole/meetingsPage.json';
import reportsPage from 'locales/en/pages/adminConsole/reportsPage.json';
import meetingDetails from 'locales/en/pages/adminConsole/meetingDetails.json';
import reportsMeeting from 'locales/en/pages/adminConsole/reportsMeeting.json';
import forgotPasswordEmail from 'locales/en/pages/auth/forgotPasswordEmail.json';
import forgotPasswordReset from 'locales/en/pages/auth/forgotPasswordReset.json';
import deleteDialogs from 'locales/en/deleteDialogs.json';
import login from 'locales/en/pages/auth/login.json';
import enums from 'locales/en/enums.json';
import errors from 'locales/en/errors.json';

import meetingFr from 'locales/fr/meeting.json';
import chatFr from 'locales/fr/pages/chat.json';
import componentsFr from 'locales/fr/components.json';
import organizationsPageFr from 'locales/fr/pages/adminConsole/organizationsPage.json';
import organizersPageFr from 'locales/fr/pages/adminConsole/organizersPage.json';
import meetingsPageFr from 'locales/fr/pages/adminConsole/meetingsPage.json';
import reportsPageFr from 'locales/fr/pages/adminConsole/reportsPage.json';
import meetingDetailsFr from 'locales/fr/pages/adminConsole/meetingDetails.json';
import reportsMeetingFr from 'locales/fr/pages/adminConsole/reportsMeeting.json';
import forgotPasswordEmailFr from 'locales/fr/pages/auth/forgotPasswordEmail.json';
import forgotPasswordResetFr from 'locales/fr/pages/auth/forgotPasswordReset.json';
import deleteDialogsFr from 'locales/fr/deleteDialogs.json';
import loginFr from 'locales/fr/pages/auth/login.json';
import enumsFr from 'locales/fr/enums.json';
import errorsFr from 'locales/fr/errors.json';

export enum Language {
	EN = 'en',
	FR = 'fr',
}

export const resources: Record<Language, any> = {
	[Language.EN]: {
		meeting,
		chat,
		components,
		organizationsPage,
		organizersPage,
		meetingsPage,
		reportsPage,
		meetingDetails,
		reportsMeeting,
		forgotPasswordEmail,
		forgotPasswordReset,
		deleteDialogs,
		login,
		enums,
		errors,
	},
	[Language.FR]: {
		meeting: meetingFr,
		chat: chatFr,
		components: componentsFr,
		organizationsPage: organizationsPageFr,
		organizersPage: organizersPageFr,
		meetingsPage: meetingsPageFr,
		reportsPage: reportsPageFr,
		meetingDetails: meetingDetailsFr,
		reportsMeeting: reportsMeetingFr,
		forgotPasswordEmail: forgotPasswordEmailFr,
		forgotPasswordReset: forgotPasswordResetFr,
		deleteDialogs: deleteDialogsFr,
		login: loginFr,
		enums: enumsFr,
		errors: errorsFr,
	},
};

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		lng: Language.EN,
		ns: [
			'components',
			'meeting',
			'chat',
			'organizationsPage',
			'organizersPage',
			'meetingsPage',
			'deleteDialogs',
			'meetingDetails',
			'reportsMeeting',
			'forgotPasswordEmail',
			'forgotPasswordReset',
			'reportsPage',
			'login',
		],
		interpolation: {
			escapeValue: false,
		},
		react: {
			transSupportBasicHtmlNodes: true,
		},
		resources,
	});

export default i18n;
