import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

import { FooterContainer } from './App.styles';

export type FooterProps = {
	greyBackground?: boolean;
} & Omit<ComponentProps<'div'>, 'ref'>;

export default function Footer({ greyBackground, ...rest }: FooterProps) {
	const { t } = useTranslation('components', { keyPrefix: 'footer' });

	return (
		<FooterContainer greyBackground={greyBackground} {...rest}>
			<Typography variant={'body2'} textAlign={'center'} color={'textSecondary'}>
				{t('text', { currentYear: dayjs().get('year') })}
			</Typography>
		</FooterContainer>
	);
}
