/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionsListResponse } from '../models/PermissionsListResponse';
import { request as __request } from '../core/request';

export class AccountService {
	/**
	 * @returns PermissionsListResponse
	 * @throws ApiError
	 */
	public static async getPermissions(): Promise<PermissionsListResponse> {
		const result = await __request({
			method: 'GET',
			path: `/accounts/permissions`,
		});
		return result.body;
	}
}
