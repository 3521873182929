import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useDefaultLanguage from 'hooks/language/useDefaultLanguage';

export function LanguageProvider({ children }: PropsWithChildren<{}>) {
	const { i18n } = useTranslation();
	const defaultLanguage = useDefaultLanguage();

	useEffect(() => {
		if (defaultLanguage.language) {
			i18n.changeLanguage(defaultLanguage.language).then(() => {});
		}
	}, [defaultLanguage.language, i18n]);

	return <>{children}</>;
}
