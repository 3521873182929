/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateMeetingRequest } from '../models/ActivateMeetingRequest';
import type { ActiveMeetingResponse } from '../models/ActiveMeetingResponse';
import type { CreateMeetingRequest } from '../models/CreateMeetingRequest';
import type { JoinMeetingResponse } from '../models/JoinMeetingResponse';
import type { LeaveMeetingResponse } from '../models/LeaveMeetingResponse';
import type { MeetingParticipantsResponse } from '../models/MeetingParticipantsResponse';
import type { MeetingResponse } from '../models/MeetingResponse';
import type { ParticipantsImportJobResponse } from '../models/ParticipantsImportJobResponse';
import type { PhonesResponse } from '../models/PhonesResponse';
import type { StartMeetingResponse } from '../models/StartMeetingResponse';
import type { UpdateMeetingRequest } from '../models/UpdateMeetingRequest';
import { request as __request } from '../core/request';
import { entities, global } from '../../../consts';

export class MeetingService {

    /**
     * @param requestBody
     * @returns MeetingResponse
     * @throws ApiError
     */
    public static async createMeeting(
        requestBody: CreateMeetingRequest,
    ): Promise<MeetingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/meetings`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a meeting by id.
     * @param meetingId
     * @returns MeetingResponse
     * @throws ApiError
     */
    public static async getMeeting(
        meetingId: number,
    ): Promise<MeetingResponse> {
        const result = await __request({
            method: 'GET',
            path: `/meetings/${meetingId}`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns any
     * @throws ApiError
     */
    public static async deleteMeeting(
        meetingId: number,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/meetings/${meetingId}`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateMeeting(
        meetingId: number,
        requestBody: UpdateMeetingRequest,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/meetings/${meetingId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns JoinMeetingResponse
     * @throws ApiError
     */
    public static async joinMeeting(
        meetingId: number,
    ): Promise<JoinMeetingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/meetings/${meetingId}/join`,
        });
        return result.body;
    }

    /**
     * Start a meeting.
     * @param meetingId
     * @returns StartMeetingResponse
     * @throws ApiError
     */
    public static async startMeeting(
        meetingId: number,
    ): Promise<StartMeetingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/meetings/${meetingId}/start`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingResponse
     * @throws ApiError
     */
    public static async stopMeeting(
        meetingId: number,
    ): Promise<MeetingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/meetings/${meetingId}/stop`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns any
     * @throws ApiError
     */
    public static async cancelMeeting(
        meetingId: number,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/meetings/${meetingId}/cancel`,
        });
        return result.body;
    }

    /**
     * @returns LeaveMeetingResponse
     * @throws ApiError
     */
    public static async leaveMeeting(): Promise<LeaveMeetingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/meetings/leave`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns MeetingResponse
     * @throws ApiError
     */
    public static async activateMeeting(
        meetingId: number,
        requestBody: ActivateMeetingRequest,
    ): Promise<MeetingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/meetings/${meetingId}/activate`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get active meeting for user.
     * @returns ActiveMeetingResponse
     * @throws ApiError
     */
    public static async getActiveMeeting(): Promise<ActiveMeetingResponse> {
        const result = await __request({
            method: 'GET',
            path: `/meetings/states/active`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingParticipantsResponse
     * @throws ApiError
     */
    public static async getParticipantsInfo(
        meetingId: number
    ): Promise<MeetingParticipantsResponse> {
        // Hack: due the continue re-render of component triggered by the parent page, I needed to load on demand when a page changes
        // and maintaining the state for the sockets calls for loading  the same information at the same time.
        const params = JSON.parse(
          sessionStorage.getItem(entities.participantsInfo) ??
          JSON.stringify({ page: 1, filter: '' })
        );

        const result = await __request({
            method: 'GET',
            path: `/meetings/${meetingId}/participants/info?page=${params.page}&limit=${global.pageLimit}&filter=${params.filter}`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingResponse
     * @throws ApiError
     */
    public static async duplicateMeeting(
        meetingId: number,
    ): Promise<MeetingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/meetings/${meetingId}/duplicate`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns PhonesResponse
     * @throws ApiError
     */
    public static async getPhones(
        meetingId: number,
    ): Promise<PhonesResponse> {
        const result = await __request({
            method: 'GET',
            path: `/meetings/${meetingId}/phones`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns any Ok
     * @throws ApiError
     */
    public static async getParticipantsImportJobState(
        meetingId: number,
    ): Promise<ParticipantsImportJobResponse | null> {
        const result = await __request({
            method: 'GET',
            path: `/meetings/${meetingId}/participants/import/job`,
        });
        return result.body;
    }

}