import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useRole } from 'context/PermissionsContext';
import { entities } from 'consts';
import { ParticipantService } from 'clients/CoreService';
import { useConfirmDialog } from 'components/ConfirmDialog';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { RoleSwitcherContainer, RoleSwitcherTypography, WSwitch } from './App.styles';

const ACTIVE_QUESTION_ERROR_ID = 'ForbiddenSwitchRoleWithActiveQuestion';
const NOT_BROADCAST_ROOM_ERROR_ID = 'WrongRoomToSwitchAttendeeError';

export default function RoleSwitcher() {
	const { t } = useTranslation('components', { keyPrefix: 'appComponents.roleSwitcher' });

	const queryClient = useQueryClient();
	const role = useRole();
	const confirmDialog = useConfirmDialog();

	const { mutate: switchRole, isLoading } = useMutation(ParticipantService.switchAttendeeRole, {
		onSuccess: () => queryClient.invalidateQueries(entities.permissions),
		onError: (error: any) => {
			if (error?.body?.id === ACTIVE_QUESTION_ERROR_ID) {
				confirmDialog({
					type: 'info',
					title: t('activeQuestionError.title'),
					description: t('activeQuestionError.description'),
					icon: WarningIcon,
					confirmText: t('activeQuestionError.confirmText'),
				});
			}

			if (error?.body?.id === NOT_BROADCAST_ROOM_ERROR_ID) {
				confirmDialog({
					type: 'info',
					title: t('wrongRoomError.title'),
					description: t('wrongRoomError.description'),
					icon: WarningIcon,
					confirmText: t('wrongRoomError.confirmText'),
				});
			}
		},
		retry: (attempt, error) =>
			attempt < 3 &&
			error?.body?.id !== ACTIVE_QUESTION_ERROR_ID &&
			error?.body?.id !== NOT_BROADCAST_ROOM_ERROR_ID,
	});

	return (
		<RoleSwitcherContainer>
			<WSwitch
				color={'primary'}
				checked={role === 'InRoomAttendee'}
				disabled={isLoading}
				onChange={() => switchRole()}
			/>
			<RoleSwitcherTypography>{t('inRoomAttendee')}</RoleSwitcherTypography>
		</RoleSwitcherContainer>
	);
}
