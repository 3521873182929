import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const Container = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

export const AdminNameContainer = styled(Typography)`
	margin-left: 0.5rem;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 20rem;
	line-height: 1.1;
`;
