import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import classnames from 'classnames';
import React from 'react';

import { WListItem, WListItemText } from './NavItem.styles';

const NavItem = ({ href, icon: Icon, title }: NavItemProps) => {
	const location = useLocation();

	const active = href
		? !!matchPath(
				{
					path: href,
					end: false,
				},
				location.pathname,
		  )
		: false;

	return (
		<WListItem button component={RouterLink} to={href} className={classnames({ active })}>
			<ListItemIcon>
				<Icon fontSize={'small'} />
			</ListItemIcon>
			<WListItemText>{title}</WListItemText>
		</WListItem>
	);
};

interface NavItemProps {
	title: string;
	icon: any;
	href: string;
}

export default NavItem;
