import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ListItem } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { green, red } from '@mui/material/colors';

export const Container = styled.div`
	padding: 1rem 1.5rem 0.5rem;

	display: flex;
	flex-direction: column;

	.margin-grow {
		margin-top: auto;
	}
`;

export const WPaper = styled(Paper)`
	margin: 0;
`;

export const Title = styled(Typography).attrs({ variant: 'h5' })``;

export const Description = styled(Typography).attrs({
	color: 'textSecondary',
})`
	margin: 1rem 0 0.5rem;
	overflow-wrap: break-word;
`;

export const InfoRow = styled.div`
	margin-top: 0.5rem;

	display: flex;
	align-items: center;
	> *:not(:first-child) {
		margin-left: 0.75rem;
	}

	svg {
		width: 1.4rem;
		height: 1.4rem;
		flex-shrink: 0;
	}
`;

export const SipEndpointRow = styled.div`
	margin: 1rem 0;

	.MuiTypography-root {
		font-weight: 600;
	}
`;

export const HorizontalLine = styled(Box)`
	width: 100%;
	height: 1px;
	background: ${({ theme }) => theme.palette.grey['500']};
`;

export const ParticipantsInfo = styled.div`
	background: ${({ theme }) => theme.palette.background.paper};

	position: sticky;
	flex-shrink: 0;

	// depends on paddings in content
	margin: 1rem -1.5rem 0;
	padding: 0.5rem 1.5rem 0;

	display: flex;
	flex-direction: row;

	@media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
		flex-direction: column;

		.callout-progress-btn {
			width: 100%;
			margin-top: 0.6rem;
		}
	}
`;

export const CalloutProgressWrapper = styled.div`
	margin-top: 1.5rem;
`;

export const AttendanceInfo = styled.div`
	flex: 1;

	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: 1fr 2fr;
	grid-template-areas:
		'invited web'
		'participating phone';
	gap: 0 3rem;

	@media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr;

		grid-template-areas:
			'invited'
			'web'
			'participating'
			'phone';
	}

	> * {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
`;

export const Bullet = styled.div`
	width: 5px;
	height: 5px;
	background: ${({ theme }) => theme.palette.text.primary};
	border-radius: 50%;
	margin-right: 0.5rem;
`;

export const CalloutPopover = styled(Popover)`
	padding: 1rem;
	overflow: hidden;
`;

export const CalloutProgressContainer = styled.div`
	padding: 1rem 3rem;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export const StartCalloutButtonContainer = styled.div`
	width: 100%;
	padding: 0 2rem 1rem 2rem;
`;

export const CalloutProgressInfo = styled.div`
	padding-left: 2rem;
`;

export const CalloutProgressRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-top: 0.5rem;

	.color {
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		margin-right: 1rem;
	}
`;

export const StyledListItem = styled(ListItem)`
	border-bottom: 1px solid #e0e0e0;
	&:hover {
		background-color: #f5f5f5;
	}
`;

export const OnlineIcon = styled(FiberManualRecordIcon)`
	color: ${green[500]};
	font-size: 12px;
`;

export const OfflineIcon = styled(FiberManualRecordIcon)`
	color: ${red[500]};
	font-size: 12px;
`;
