/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CsvReportType {
    IN_MEETING_POLL = 'InMeetingPoll',
    EXTENDED_POLL = 'ExtendedPoll',
    GENERAL = 'General',
    PARTICIPANT = 'Participant',
    QUESTION = 'Question',
    PARTICIPANTS_IMPORT_ERRORS = 'ParticipantsImportErrors',
}