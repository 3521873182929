/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HistoryRoomType } from '../models/HistoryRoomType';
import type { WebsocketEventName } from '../models/WebsocketEventName';
import { request as __request } from '../core/request';

export class SystemService {
	/**
	 * @returns any
	 * @throws ApiError
	 */
	public static async healthy(): Promise<any> {
		const result = await __request({
			method: 'GET',
			path: `/healthy`,
		});
		return result.body;
	}

	/**
	 * Endpoint for dev purpose. It allows us to get all needed not related to endpoints *.ts types on FE when we rebuild a client.
	 * @returns any
	 * @throws ApiError
	 */
	public static async getDevInfo(): Promise<Array<WebsocketEventName | HistoryRoomType>> {
		const result = await __request({
			method: 'GET',
			path: `/websocket/events`,
		});
		return result.body;
	}

	public static async getBackendVersion(): Promise<string> {
		const result = await __request({
			method: 'GET',
			path: `/version`,
		});
		return result.body;
	}
}
