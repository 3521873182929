import React from 'react';
import { AppBarProps } from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useAuthFinished } from 'hooks/auth/useAuthFinished';
import AvatarPopover from './AvatarPopover';
import { WAppBar, WLogo, RightSide } from './App.styles';
import RoleSwitcher from './RoleSwitcher';
import LanguageSwitcher from './LanguageSwitcher';

export interface AppHeaderProps extends AppBarProps {
	hideUser?: boolean;
	noHomeLink?: boolean;
	showAttendeeRoleSwitcher?: boolean;
	onMenuClick?: () => void;
}

const AppHeader = ({
	hideUser,
	noHomeLink,
	showAttendeeRoleSwitcher,
	onMenuClick,
	...props
}: AppHeaderProps) => {
	const [, setAuthFinished] = useAuthFinished();

	return (
		<WAppBar color={'primary'} {...props}>
			<Grid container alignItems={'center'} spacing={1} width={'unset'}>
				{onMenuClick && (
					<Grid item>
						<Box sx={{ display: { md: 'block', lg: 'none' } }}>
							<IconButton color={'inherit'} onClick={onMenuClick}>
								<MenuIcon />
							</IconButton>
						</Box>
					</Grid>
				)}
				<Grid item>
					{noHomeLink ? (
						<WLogo />
					) : (
						<Link href={'/'} onClick={() => setAuthFinished(false)}>
							<WLogo />
						</Link>
					)}
				</Grid>
			</Grid>

			<RightSide>
				<LanguageSwitcher />
				{!hideUser && <AvatarPopover />}
				{showAttendeeRoleSwitcher && <RoleSwitcher />}
			</RightSide>
		</WAppBar>
	);
};

export default AppHeader;
