/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvReportResponse } from '../models/CsvReportResponse';
import type { CsvReportType } from '../models/CsvReportType';
import type { DataQueryOrder } from '../models/DataQueryOrder';
import type { DataQueryOrderBy } from '../models/DataQueryOrderBy';
import type { DataQueryPage } from '../models/DataQueryPage';
import type { DataQueryRowsPerPage } from '../models/DataQueryRowsPerPage';
import type { DataQuerySearchText } from '../models/DataQuerySearchText';
import type { MeetingReportResponse } from '../models/MeetingReportResponse';
import type { MeetingVideoResponse } from '../models/MeetingVideoResponse';
import type { MeetingVoiceBroadcastingReportResponse } from '../models/MeetingVoiceBroadcastingReportResponse';
import type { ParticipantsReportResponse } from '../models/ParticipantsReportResponse';
import type { PollReportList } from '../models/PollReportList';
import type { QuestionsReportResponse } from '../models/QuestionsReportResponse';
import type { QuestionsSummaryReportResponse } from '../models/QuestionsSummaryReportResponse';
import type { ReportsListResponse } from '../models/ReportsListResponse';
import { request as __request } from '../core/request';

export class ReportService {

    /**
     * @param rowsPerPage
     * @param page
     * @param orderBy
     * @param order
     * @param filters
     * @param searchText
     * @returns ReportsListResponse
     * @throws ApiError
     */
    public static async getReports(
        rowsPerPage: DataQueryRowsPerPage,
        page: DataQueryPage,
        orderBy?: DataQueryOrderBy,
        order?: DataQueryOrder,
        filters?: Array<any>,
        searchText?: DataQuerySearchText,
    ): Promise<ReportsListResponse> {
        const result = await __request({
            method: 'GET',
            path: `/reports`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'orderBy': orderBy,
                'order': order,
                'filters': filters,
                'searchText': searchText,
            },
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param rowsPerPage
     * @param page
     * @param orderBy
     * @param order
     * @param filters
     * @param searchText
     * @returns PollReportList
     * @throws ApiError
     */
    public static async getPollsReport(
        meetingId: number,
        rowsPerPage: DataQueryRowsPerPage,
        page: DataQueryPage,
        orderBy?: DataQueryOrderBy,
        order?: DataQueryOrder,
        filters?: Array<any>,
        searchText?: DataQuerySearchText,
    ): Promise<PollReportList> {
        const result = await __request({
            method: 'GET',
            path: `/reports/meetings/${meetingId}/polls`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'orderBy': orderBy,
                'order': order,
                'filters': filters,
                'searchText': searchText,
            },
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingVideoResponse
     * @throws ApiError
     */
    public static async getMeetingVideo(
        meetingId: number,
    ): Promise<MeetingVideoResponse> {
        const result = await __request({
            method: 'GET',
            path: `/reports/meetings/${meetingId}/videos`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingReportResponse
     * @throws ApiError
     */
    public static async getMeetingReport(
        meetingId: number,
    ): Promise<MeetingReportResponse> {
        const result = await __request({
            method: 'GET',
            path: `/reports/meeting/${meetingId}`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param rowsPerPage
     * @param page
     * @param orderBy
     * @param order
     * @param filters
     * @param searchText
     * @returns QuestionsReportResponse
     * @throws ApiError
     */
    public static async getQuestionsReport(
        meetingId: number,
        rowsPerPage: DataQueryRowsPerPage,
        page: DataQueryPage,
        orderBy?: DataQueryOrderBy,
        order?: DataQueryOrder,
        filters?: Array<any>,
        searchText?: DataQuerySearchText,
    ): Promise<QuestionsReportResponse> {
        const result = await __request({
            method: 'GET',
            path: `/reports/meetings/${meetingId}/questions`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'orderBy': orderBy,
                'order': order,
                'filters': filters,
                'searchText': searchText,
            },
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param rowsPerPage
     * @param page
     * @param orderBy
     * @param order
     * @param filters
     * @param searchText
     * @returns ParticipantsReportResponse
     * @throws ApiError
     */
    public static async getParticipantsReport(
        meetingId: number,
        rowsPerPage: DataQueryRowsPerPage,
        page: DataQueryPage,
        orderBy?: DataQueryOrderBy,
        order?: DataQueryOrder,
        filters?: Array<any>,
        searchText?: DataQuerySearchText,
    ): Promise<ParticipantsReportResponse> {
        const result = await __request({
            method: 'GET',
            path: `/reports/meeting/${meetingId}/participants`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'orderBy': orderBy,
                'order': order,
                'filters': filters,
                'searchText': searchText,
            },
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns QuestionsSummaryReportResponse
     * @throws ApiError
     */
    public static async getQuestionsSummaryReport(
        meetingId: number,
    ): Promise<QuestionsSummaryReportResponse> {
        const result = await __request({
            method: 'GET',
            path: `/reports/meetings/${meetingId}/questions/summary`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param type
     * @returns CsvReportResponse
     * @throws ApiError
     */
    public static async getCsvReport(
        meetingId: number,
        type: CsvReportType,
    ): Promise<CsvReportResponse> {
        const result = await __request({
            method: 'GET',
            path: `/reports/meetings/${meetingId}/csv`,
            query: {
                'type': type,
            },
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingVoiceBroadcastingReportResponse
     * @throws ApiError
     */
    public static async getMeetingVoiceBroadcastingReport(
        meetingId: number,
    ): Promise<MeetingVoiceBroadcastingReportResponse> {
        const result = await __request({
            method: 'GET',
            path: `/reports/meeting/${meetingId}/broadcasting`,
        });
        return result.body;
    }

}