/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OperationUnion {
    GET_MEETING_VIDEO = 'getMeetingVideo',
    GET_PERMISSIONS = 'getPermissions',
    VERIFY_ANONYMOUS_PARTICIPANT = 'verifyAnonymousParticipant',
    SET_POLL_RESULT_SETTINGS = 'setPollResultSettings',
    GET_ADMIN = 'getAdmin',
    VERIFY_TOKEN = 'verifyToken',
    GET_ALL_ADMINS = 'getAllAdmins',
    CREATE_MEETING = 'createMeeting',
    ACTIVATE_MEETING = 'activateMeeting',
    START_MEETING = 'startMeeting',
    STOP_MEETING = 'stopMeeting',
    GET_ORGANIZATION = 'getOrganization',
    GET_ALL_ORGANIZATIONS = 'getAllOrganizations',
    CREATE_ORGANIZATION = 'createOrganization',
    UPDATE_ORGANIZATION = 'updateOrganization',
    DELETE_ORGANIZATION = 'deleteOrganization',
    GET_MEETING = 'getMeeting',
    GET_ACTIVE_MEETING = 'getActiveMeeting',
    GET_ALL_MEETINGS = 'getAllMeetings',
    DELETE_MEETING = 'deleteMeeting',
    GET_PARTICIPANTS = 'getParticipants',
    GET_ALL_PARTICIPANTS = 'getAllParticipants',
    UPDATE_PARTICIPANT = 'updateParticipant',
    DELETE_PARTICIPANT = 'deleteParticipant',
    CREATE_QUESTION = 'createQuestion',
    CREATE_ADMIN = 'createAdmin',
    UPDATE_ADMIN = 'updateAdmin',
    ADD_PARTICIPANT = 'addParticipant',
    GET_ALL_QUESTIONS = 'getAllQuestions',
    GET_ACTIVE_QUESTION = 'getActiveQuestion',
    SET_PARTICIPANT_LIVE = 'setParticipantLive',
    JOIN_MEETING = 'joinMeeting',
    REVIEW_QUESTION = 'reviewQuestion',
    LEAVE_MEETING = 'leaveMeeting',
    END_SCREENING = 'endScreening',
    UPDATE_QUESTION = 'updateQuestion',
    DELETE_QUESTION = 'deleteQuestion',
    IMPORT_PARTICIPANTS = 'importParticipants',
    GET_ALL_POLLS = 'getAllPolls',
    CREATE_POLL = 'createPoll',
    UPDATE_POLL = 'updatePoll',
    DELETE_POLL = 'deletePoll',
    START_POLL = 'startPoll',
    STOP_POLL = 'stopPoll',
    GET_ACTIVE_POLLS = 'getActivePolls',
    ANSWER_POLL = 'answerPoll',
    CHANGE_PARTICIPANT_SETTINGS = 'changeParticipantSettings',
    GET_LIVE_PARTICIPANT_SETTINGS = 'GetLiveParticipantSettings',
    GET_LIVE_PARTICIPANTS_LIST = 'getLiveParticipantsList',
    GET_ROOM_STATES = 'getRoomStates',
    GET_REPORTS = 'getReports',
    GET_MEETING_REPORT = 'getMeetingReport',
    GET_POLLS_REPORT = 'getPollsReport',
    GET_PARTICIPANTS_REPORT = 'getParticipantsReport',
    GET_QUESTIONS_SUMMARY_REPORT = 'getQuestionsSummaryReport',
    GET_QUESTIONS_REPORT = 'getQuestionsReport',
    UPDATE_MEETING = 'updateMeeting',
    GET_SIGNED_POST_URLS = 'getSignedPostUrls',
    SET_MEETING_DOCUMENT = 'setMeetingDocument',
    GET_MEETING_DOCUMENTS = 'getMeetingDocuments',
    DELETE_MEETING_DOCUMENT = 'deleteMeetingDocument',
    SCREEN_NEXT_PARTICIPANT = 'screenNextParticipant',
    DISCONNECT_PARTICIPANT_FROM_BREAKOUT_ROOM = 'disconnectParticipantFromBreakoutRoom',
    GET_SCREENING_QUESTION = 'getScreeningQuestion',
    RESTORE_ADMIN = 'restoreAdmin',
    RESTORE_ORGANIZATION = 'restoreOrganization',
    UPDATE_PRIORITY = 'updatePriority',
    VERIFY_PARTICIPANT = 'verifyParticipant',
    SEND_VERIFICATION_CODE = 'sendVerificationCode',
    DELETE_ADMIN = 'deleteAdmin',
    CAN_ACCEPT_PARTICIPANT = 'canAcceptParticipant',
    CREATE_CHAT = 'createChat',
    GET_USER_TOKEN_CHAT = 'getUserTokenChat',
    GET_CHAT_PARTICIPANTS = 'getChatParticipants',
    GET_CSV_REPORT = 'getCsvReport',
    GET_PARTICIPANTS_INFO = 'getParticipantsInfo',
    GET_PHONES = 'getPhones',
    CANCEL_MEETING = 'cancelMeeting',
    GET_SPEAKERS = 'getSpeakers',
    SET_ACTIVE_SESSION = 'setActiveSession',
    CHANGE_PARTICIPANT_PERMISSIONS = 'changeParticipantPermissions',
    GET_MEETING_PARTICIPANTS = 'getMeetingParticipants',
    SWITCH_ATTENDEE_ROLE = 'switchAttendeeRole',
    GET_CALLOUT_INFO = 'getCalloutInfo',
    START_CALLOUT = 'startCallout',
    GET_CALLOUT_SETTINGS = 'getCalloutSettings',
    SET_CALLOUT_SETTINGS = 'setCalloutSettings',
    GET_VOICE_BROADCASTING = 'getVoiceBroadcasting',
    SET_VOICE_BROADCASTING = 'setVoiceBroadcasting',
    CANCEL_VOICE_BROADCASTING = 'cancelVoiceBroadcasting',
    GET_VOICE_BROADCASTING_PROGRESS = 'getVoiceBroadcastingProgress',
    GET_MEETING_VOICE_BROADCASTING_REPORT = 'getMeetingVoiceBroadcastingReport',
    SET_EXTENDED_POLLS_RESULT_SETTINGS = 'setExtendedPollsResultSettings',
    DUPLICATE_MEETING = 'duplicateMeeting',
    SET_POLL_END_TIME = 'SetPollEndTime',
    SEND_NOTIFICATION = 'SendNotification',
    GENERATE_JITSI_JWT = 'GenerateJitsiJwt',
    GET_PARTICIPANTS_IMPORT_JOB_STATE = 'GetParticipantsImportJobState',
}