/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveQuestionResponse } from '../models/ActiveQuestionResponse';
import type { CreateQuestionRequest } from '../models/CreateQuestionRequest';
import type { Pagination_QuestionResponse_ } from '../models/Pagination_QuestionResponse_';
import type { QuestionResponse } from '../models/QuestionResponse';
import type { QuestionReviewRequest } from '../models/QuestionReviewRequest';
import type { ResultQuestionResponse } from '../models/ResultQuestionResponse';
import type { UpdatePrioritiesRequest } from '../models/UpdatePrioritiesRequest';
import type { UpdateQuestionRequest } from '../models/UpdateQuestionRequest';
import { request as __request } from '../core/request';

export class QuestionService {

    /**
     * @param rowsPerPage
     * @param page
     * @param order
     * @param filters
     * @param orderBy
     * @param searchText
     * @returns any
     * @throws ApiError
     */
    public static async getAllQuestions(
        rowsPerPage: number,
        page: number,
        order: 'ASC' | 'DESC' = 'ASC',
        filters?: Array<string>,
        orderBy?: string,
        searchText?: string,
    ): Promise<(Pagination_QuestionResponse_ & {
        totalCount: number,
    })> {
        const result = await __request({
            method: 'GET',
            path: `/questions`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'order': order,
                'filters': filters,
                'orderBy': orderBy,
                'searchText': searchText,
            },
        });
        return result.body;
    }

    /**
     * Raise a hand, or create a question.
     * @param requestBody
     * @returns QuestionResponse
     * @throws ApiError
     */
    public static async createQuestion(
        requestBody: CreateQuestionRequest,
    ): Promise<QuestionResponse> {
        const result = await __request({
            method: 'POST',
            path: `/questions`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param questionId
     * @param requestBody
     * @returns QuestionResponse
     * @throws ApiError
     */
    public static async reviewQuestion(
        questionId: number,
        requestBody: QuestionReviewRequest,
    ): Promise<QuestionResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/questions/${questionId}/reviews`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param questionId
     * @param requestBody
     * @returns QuestionResponse
     * @throws ApiError
     */
    public static async updateQuestion(
        questionId: number,
        requestBody: UpdateQuestionRequest,
    ): Promise<QuestionResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/questions/${questionId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param questionId
     * @returns QuestionResponse
     * @throws ApiError
     */
    public static async deleteQuestion(
        questionId: number,
    ): Promise<QuestionResponse> {
        const result = await __request({
            method: 'DELETE',
            path: `/questions/${questionId}/undo`,
        });
        return result.body;
    }

    /**
     * Update priority.
     * if previousQuestionId = -1, question is moved to the top
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updatePriority(
        requestBody: UpdatePrioritiesRequest,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/questions/priority/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get active user question.
     * @returns ActiveQuestionResponse
     * @throws ApiError
     */
    public static async getActiveQuestion(): Promise<ActiveQuestionResponse> {
        const result = await __request({
            method: 'GET',
            path: `/questions/states/active`,
        });
        return result.body;
    }

    /**
     * @returns ResultQuestionResponse
     * @throws ApiError
     */
    public static async getScreeningQuestion(): Promise<ResultQuestionResponse> {
        const result = await __request({
            method: 'GET',
            path: `/questions/screening`,
        });
        return result.body;
    }

}