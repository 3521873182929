import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import useWindowDimension from 'hooks/window/useWindowDimension';
import { entities } from 'consts';
import { AdminService } from 'clients/CoreService';
import { useSnackbarOnError } from 'hooks';
import { HorizontalLine } from 'components/MeetingInfo/MeetingInfo.styles';
import { useLogout } from 'hooks/auth/useLogout';

import DashboardHeader from './AppHeader';
import DashboardSidebar from './AppSidebar';
import Footer from './Footer';
import { AdminName } from './AvatarPopover';
import {
	Container,
	ChildrenWrapper,
	Content,
	WAvatarIcon,
	SidebarAvatarContainer,
} from './App.styles';

const AppWrapper = () => {
	const { t } = useTranslation('components', { keyPrefix: 'appComponents.authWrapper' });
	const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);
	const windowDimension = useWindowDimension();
	const logout = useLogout();

	const { data: admin } = useQuery(
		[entities.administrators, entities.administratorsMe],
		() => AdminService.getAdmin(),
		{
			onError: useSnackbarOnError(),
		},
	);

	return (
		<Container>
			<DashboardHeader
				onMenuClick={() => setIsDrawerOpened(opened => !opened)}
				hideUser={windowDimension.down('lg')}
			/>
			<SwipeableDrawer
				onClose={() => setIsDrawerOpened(false)}
				onOpen={() => setIsDrawerOpened(true)}
				open={isDrawerOpened}
			>
				<SidebarAvatarContainer>
					<Grid item>
						<WAvatarIcon />
					</Grid>
					<Grid item>
						{admin && <AdminName admin={admin} color={'primary'} fontWeight={'500'} />}
					</Grid>
				</SidebarAvatarContainer>
				<DashboardSidebar elevation={windowDimension.down('lg') ? 0 : 1} />
				<HorizontalLine />
				<Button variant={'text'} onClick={() => logout()}>
					{t('logout')}
				</Button>
			</SwipeableDrawer>
			<Content>
				{windowDimension.up('lg') && <DashboardSidebar style={{ gridArea: 'sidebar' }} />}
				<ChildrenWrapper style={{ gridArea: 'children' }}>
					<Outlet />
				</ChildrenWrapper>
				<Footer style={{ gridArea: 'footer' }} />
			</Content>
		</Container>
	);
};

export default AppWrapper;
