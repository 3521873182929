import { AccountResponse, OpenAPI as AccountOpenAPI } from 'clients/AccountService';
import { OpenAPI as CoreOpenAPi } from 'clients/CoreService';

import { SharedStateReturn, useSharedState } from 'hooks/state/useSharedState';
import { entities } from 'consts';

export type ApiToken = Pick<AccountResponse, 'accessToken' | 'refreshToken'>;

export function useApiToken(): SharedStateReturn<null | ApiToken> {
	const [apiToken, setApiToken] = useSharedState(
		entities.accessToken,
		// get default value from localstorage
		getAccessToken(),
		// whenever changes - copy it to localstorage
		setAccessToken,
	);

	if (apiToken) {
		AccountOpenAPI.TOKEN = apiToken.accessToken;
		CoreOpenAPi.TOKEN = apiToken.accessToken;
	} else {
		delete AccountOpenAPI.TOKEN;
		delete CoreOpenAPi.TOKEN;
	}

	return [apiToken, setApiToken];
}

// persist & restore AccountResponse to localstorage
const ACCOUNT_RESPONSE_KEY = 'ACCOUNT_RESPONSE';

const getAccessToken = (): null | ApiToken => {
	const restored = localStorage.getItem(ACCOUNT_RESPONSE_KEY);
	return restored ? JSON.parse(restored) : null;
};

const setAccessToken = (token: null | ApiToken) => {
	localStorage.setItem(ACCOUNT_RESPONSE_KEY, JSON.stringify(token));
};
