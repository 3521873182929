import React from 'react';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { PaperProps } from '@mui/material/Paper';

import { ReactComponent as OrganizationsIcon } from 'assets/icons/organizations.svg';
import { ReactComponent as AdminsIcon } from 'assets/icons/admins.svg';
import { ReactComponent as MeetingsIcon } from 'assets/icons/meetings.svg';
import { ReactComponent as ReportsIcon } from 'assets/icons/reports.svg';

import { UserRole, PermissionsListResponse } from 'clients/CoreService';
import { useRole } from 'context/PermissionsContext';

import NavItem from './NavItem';
import { SideBarHolder } from './App.styles';

const AppSidebar = ({ ...rest }: PaperProps) => {
	const { t } = useTranslation('components', { keyPrefix: 'appComponents.appSidebar' });
	const role = useRole();

	const items: {
		href: string;
		icon: React.ComponentType;
		title: string;
		allowedRoles?: PermissionsListResponse['role'][];
	}[] = [
		{
			href: '/admin/organizations',
			icon: OrganizationsIcon,
			title: t('organizations'),
			allowedRoles: [UserRole.PLATFORM_ADMIN],
		},
		{
			href: '/admin/organizers',
			icon: AdminsIcon,
			title: t('organizers'),
			allowedRoles: [UserRole.PLATFORM_ADMIN],
		},
		{
			href: '/admin/meetings',
			icon: MeetingsIcon,
			title: t('meetings'),
		},
		{
			href: '/admin/reports',
			icon: ReportsIcon,
			title: t('reports'),
		},
	];

	return (
		<SideBarHolder square {...rest}>
			<List>
				{items
					.filter(({ allowedRoles }) => (allowedRoles && role ? allowedRoles.includes(role) : true))
					.map(item => (
						<NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
					))}
			</List>
		</SideBarHolder>
	);
};

export default AppSidebar;
