import { useCallback, useMemo, useState } from 'react';
import theme from 'themes';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export default function useWindowDimension() {
	const [dimension, setDimension] = useState(window?.innerWidth || 0);

	window.addEventListener('resize', () => {
		setDimension(window?.innerWidth || 0);
	});

	const up = useCallback(
		(breakpoint: Breakpoint) => {
			return theme.breakpoints.values[breakpoint] <= dimension;
		},
		[dimension],
	);

	const down = useCallback(
		(breakpoint: Breakpoint) => {
			return dimension < theme.breakpoints.values[breakpoint];
		},
		[dimension],
	);

	return useMemo(
		() => ({
			dimension,
			up,
			down,
		}),
		[dimension, up, down],
	);
}
