import { useEffect } from 'react';

import { ApiError, Service, AccountResponse } from 'clients/AccountService';
import { useApiToken } from 'hooks/auth/useApiToken';
import { useLogout } from 'hooks/auth/useLogout';

const originalFetch = global.fetch;

export function useAutoTokenRefresh() {
	const [apiToken, setApiToken] = useApiToken();
	const logout = useLogout();

	useEffect(() => {
		let updateTokenPromise: Promise<AccountResponse> | null = null;

		global.fetch = async (url: RequestInfo, config?: RequestInit): Promise<Response> => {
			// try to make request as usual
			const response = await originalFetch(url, config);

			// if Unauthorized was thrown & it's not the prolong token endpoint that has thrown this
			if (response.status === 401 && !response.url.includes('/account/token')) {
				console.log('refreshing token using', apiToken?.refreshToken);
				// we can't prolong token without refresh token - straight up logout
				if (!apiToken?.refreshToken) {
					logout();
					// and return original error
					return response;
				}

				let newAuthToken: AccountResponse | null = null;
				if (!updateTokenPromise) {
					// initialise token refresh promise
					updateTokenPromise = Service.token({
						refreshToken: apiToken.refreshToken,
						accessToken: apiToken.accessToken,
					});
					try {
						setApiToken((newAuthToken = await updateTokenPromise));
					} catch (e) {
						const error = e as ApiError;
						if (error.status === 400 || error.status === 401 || error.status === 403) {
							// access token can't be refreshed
							logout();
						} else {
							// something else happened, e.g. connection error
							console.error('could not refresh the token', e);
							throw e;
						}
					} finally {
						updateTokenPromise = null;
					}
				} else {
					// or wait for prolonging promise to resolve, if it was started by another thread
					newAuthToken = await updateTokenPromise;
				}

				// if new token was received - retry original request, but with updated authorization
				if (newAuthToken) {
					const newHeaders = new Headers(config?.headers);
					newHeaders.set('Authorization', `Bearer ${newAuthToken.accessToken}`);

					return originalFetch(url, { ...config, headers: newHeaders });
				}
			}

			// otherwise return original response
			return response;
		};

		return () => {
			global.fetch = originalFetch;
		};
	}, [apiToken, setApiToken, logout]);
}
