/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnswerPollRequest } from '../models/AnswerPollRequest';
import type { CreatePollRequest } from '../models/CreatePollRequest';
import type { PollConductionType } from '../models/PollConductionType';
import type { PollResponse } from '../models/PollResponse';
import type { PollResultResponse } from '../models/PollResultResponse';
import type { PollResultSettingsRequest } from '../models/PollResultSettingsRequest';
import type { PollsListResponse } from '../models/PollsListResponse';
import type { SetPollEndTimeRequest } from '../models/SetPollEndTimeRequest';
import type { UpdatePollRequest } from '../models/UpdatePollRequest';
import type { VoterPollsListResponse } from '../models/VoterPollsListResponse';
import { request as __request } from '../core/request';

export class PollService {

    /**
     * @param pollId
     * @returns PollResponse
     * @throws ApiError
     */
    public static async stopPoll(
        pollId: number,
    ): Promise<PollResponse> {
        const result = await __request({
            method: 'POST',
            path: `/polls/${pollId}/stop`,
            query: {
                'pollId': pollId,
            },
        });
        return result.body;
    }

    /**
     * @param pollId
     * @returns PollResponse
     * @throws ApiError
     */
    public static async startPoll(
        pollId: number,
    ): Promise<PollResponse> {
        const result = await __request({
            method: 'POST',
            path: `/polls/${pollId}/start`,
            query: {
                'pollId': pollId,
            },
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns PollResponse
     * @throws ApiError
     */
    public static async createPoll(
        meetingId: number,
        requestBody: CreatePollRequest,
    ): Promise<PollResponse> {
        const result = await __request({
            method: 'POST',
            path: `/polls/meetings/${meetingId}`,
            query: {
                'meetingId': meetingId,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param pollId
     * @param requestBody
     * @returns PollResponse
     * @throws ApiError
     */
    public static async updatePoll(
        pollId: number,
        requestBody: UpdatePollRequest,
    ): Promise<PollResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/polls/${pollId}`,
            query: {
                'pollId': pollId,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param pollId
     * @returns any
     * @throws ApiError
     */
    public static async deletePoll(
        pollId: number,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/polls/${pollId}`,
            query: {
                'pollId': pollId,
            },
        });
        return result.body;
    }

    /**
     * @param pollId
     * @param requestBody
     * @returns VoterPollsListResponse
     * @throws ApiError
     */
    public static async answerPoll(
        pollId: number,
        requestBody: AnswerPollRequest,
    ): Promise<VoterPollsListResponse> {
        const result = await __request({
            method: 'POST',
            path: `/polls/${pollId}/answer`,
            query: {
                'pollId': pollId,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param conductionType
     * @param meetingId
     * @returns PollsListResponse
     * @throws ApiError
     */
    public static async getAllPolls(
        conductionType: PollConductionType,
        meetingId?: number,
    ): Promise<PollsListResponse> {
        const result = await __request({
            method: 'GET',
            path: `/polls`,
            query: {
                'conductionType': conductionType,
                'meetingId': meetingId,
            },
        });
        return result.body;
    }

    /**
     * @param conductionType
     * @param meetingId
     * @returns VoterPollsListResponse
     * @throws ApiError
     */
    public static async getActivePolls(
        conductionType: PollConductionType,
        meetingId?: number,
    ): Promise<VoterPollsListResponse> {
        const result = await __request({
            method: 'GET',
            path: `/polls/states/active`,
            query: {
                'conductionType': conductionType,
                'meetingId': meetingId,
            },
        });
        return result.body;
    }

    /**
     * @param pollId
     * @param requestBody
     * @returns PollResultResponse
     * @throws ApiError
     */
    public static async setPollResultSettings(
        pollId: number,
        requestBody: PollResultSettingsRequest,
    ): Promise<PollResultResponse> {
        const result = await __request({
            method: 'POST',
            path: `/polls/${pollId}/results/settings`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns PollsListResponse
     * @throws ApiError
     */
    public static async setExtendedPollsResultSettings(
        requestBody: PollResultSettingsRequest,
    ): Promise<PollsListResponse> {
        const result = await __request({
            method: 'POST',
            path: `/polls/extended/all/results/settings`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param pollId
     * @param requestBody
     * @returns PollResponse Ok
     * @throws ApiError
     */
    public static async setPollEndTime(
        pollId: number,
        requestBody: SetPollEndTimeRequest,
    ): Promise<PollResponse> {
        const result = await __request({
            method: 'POST',
            path: `/polls/${pollId}/end-time`,
            query: {
                'pollId': pollId,
            },
            body: requestBody,
        });
        return result.body;
    }

}