import { useQueryClient } from 'react-query';
import { useCallback, useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

import { AccountResponse } from 'clients/AccountService';
import { ApiToken, useApiToken } from 'hooks/auth/useApiToken';

export function useFullTokenReplace() {
	const newTokenRequest = useMemo<{ token?: null | ApiToken }>(() => ({ token: null }), []);

	const [apiToken, setApiToken] = useApiToken();
	const queryClient = useQueryClient();

	useEffect(() => {
		// as soon as requested token & token from shared state become equal -
		// the setup is complete - reset query client accordingly
		if (isEqual(apiToken, newTokenRequest.token)) {
			delete newTokenRequest.token;
			queryClient.resetQueries();
		}
	}, [apiToken, newTokenRequest, queryClient]);

	return useCallback(
		(newToken: AccountResponse | null) => {
			newTokenRequest.token = newToken;
			setApiToken(newToken);
		},
		[newTokenRequest, setApiToken],
	);
}

export function useLogout() {
	const fullTokenReplace = useFullTokenReplace();
	return useCallback(() => fullTokenReplace(null), [fullTokenReplace]);
}
