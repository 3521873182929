import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import styled from 'styled-components';

export const WListItem: typeof ListItem = styled(ListItem)`
	border-radius: 4px;
	min-width: 13rem;
	padding: 1rem 1.5rem;

	.MuiListItemIcon-root {
		width: 1rem;
		height: 1rem;
		color: ${({ theme }) => theme.palette.text.primary};
		margin-right: 0.5rem;
		svg {
			width: 100%;
			height: 100%;
			path {
				transition: 300ms ease;
				fill: currentColor;
				will-change: fill;
			}
		}
	}

	transition: 300ms ease;
	will-change: padding, background, color;

	&.active {
		background: ${({ theme }) => `${theme.palette.primary.main}18`};
		color: ${({ theme }) => theme.palette.primary.main};
		svg {
			path {
				fill: ${({ theme }) => theme.palette.primary.main};
			}
		}
	}
`;

export const WListItemText = styled(Typography)`
	font-weight: 600;
`;
