export const entities = {
	sharedState: 'SHARED_STATE',
	accessToken: 'ACCESS_TOKEN',
	attendees: 'ATTENDEES',
	authFinished: 'AUTH_FINISHED',
	sessionId: 'SESSION_ID',
	socket: 'SOCKET',
	permissions: 'PERMISSIONS',
	magicToken: 'MAGIC_TOKEN',
	organizations: 'ORGANIZATIONS',
	activeMeeting: 'ACTIVE_MEETING',
	activeQuestion: 'ACTIVE_QUESTION',
	questions: 'QUESTIONS',
	questionsList: 'QUESTION_LIST',
	participants: 'PARTICIPANTS',
	participantsInfo: 'PARTICIPANTS_INFO',
	myLiveSettings: 'MY_LIVE_SETTINGS',
	polls: 'POLLS',
	activePolls: 'ACTIVE_POLLS',
	roomsStatus: 'CHANNELS_INFO',
	documents: 'DOCUMENTS',
	administrators: 'ADMINISTRATORS',
	administratorsMe: 'ADMINISTRATORS_ME',
	chatNotifications: 'CHAT_NOTIFICATIONS',
	participantsImportJob: 'PARTICIPANTS_IMPORT_JOB',

	readyScreener: 'READYSCREENER',
	screeningQuestion: 'SCREENINGQUESTION',

	meetings: 'MEETINGS',
	meetingPhones: 'MEETINGS_PHONES',
	meetingParticipants: 'MEETINGS_PARTICIPANTS',
	meetingSpeakers: 'MEETING_SPEAKERS',
	meetingOrganization: 'MEETING_ORGANIZATION',
	calloutInfo: 'CALLOUT_INFO',
	calloutSettings: 'CALLOUT_SETTINGS',
	voiceBroadcasting: 'VOICE_BROADCASTING',

	reports: 'REPORTS',
	reportsOverview: 'REPORTS_OVERVIEW',
	voiceBroadcastingOverview: 'VOICE_BROADCASTING_OVERVIEW',
	reportsPolls: 'REPORTS_POLLS',
	reportsQuestions: 'REPORTS_QUESTIONS',
	reportsParticipants: 'REPORTS_PARTICIPANTS',
	reportsDownload: 'REPORTS_DOWNLOAD',

	chat: 'CHAT',
	chatUnreads: 'CHAT_UNREADS',
	chatPresence: 'CHAT_PRESENCE',

	instantMeetingJwt: 'INSTANT_MEETING_JWT',
};
