/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminResponse } from '../models/AdminResponse';
import type { CreateAdminRequest } from '../models/CreateAdminRequest';
import type { UpdateAdminRequest } from '../models/UpdateAdminRequest';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * Get admin profile information.
     * @returns AdminResponse
     * @throws ApiError
     */
    public static async getAdmin(): Promise<AdminResponse> {
        const result = await __request({
            method: 'GET',
            path: `/admins/profile`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns AdminResponse
     * @throws ApiError
     */
    public static async createAdmin(
        requestBody: CreateAdminRequest,
    ): Promise<AdminResponse> {
        const result = await __request({
            method: 'POST',
            path: `/admins`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param adminId
     * @param requestBody
     * @returns AdminResponse
     * @throws ApiError
     */
    public static async updateAdmin(
        adminId: number,
        requestBody: UpdateAdminRequest,
    ): Promise<AdminResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/admins/${adminId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param adminId
     * @returns any
     * @throws ApiError
     */
    public static async deleteAdmin(
        adminId: number,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/admins/${adminId}`,
        });
        return result.body;
    }

    /**
     * @param adminId
     * @returns AdminResponse
     * @throws ApiError
     */
    public static async restoreAdmin(
        adminId: number,
    ): Promise<AdminResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/admins/${adminId}/restore`,
        });
        return result.body;
    }

}