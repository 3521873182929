/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ParticipantsImportJobStatus {
    INITIATED = 'Initiated',
    VALIDATING = 'Validating',
    IMPORTING = 'Importing',
    COMPLETED = 'Completed',
    FAILED = 'Failed',
}