import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const LayoutContainer = styled.div`
	flex-grow: 1;
	box-sizing: border-box;
	padding: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.palette.background.default};
`;

export const FormContainer = styled.div`
	width: 35rem;
	display: flex;
	justify-content: start;
	align-items: center;
	padding-left: 2rem 2rem 1.5rem;
	background: ${({ theme }) => theme.palette.background.paper};
	border-radius: 1rem;
`;
