/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ParticipantRole {
    ATTENDEE = 'Attendee',
    SCREENER = 'Screener',
    ORGANIZATION_ADMIN = 'OrganizationAdmin',
    PLATFORM_ADMIN = 'PlatformAdmin',
    OBSERVER = 'Observer',
    IN_ROOM_ATTENDEE = 'InRoomAttendee',
    SPEAKER = 'Speaker',
    MAIN_ROOM_ATTENDEE = 'MainRoomAttendee',
}