import React, { useEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ReactComponent as AvatarIcon } from 'assets/icons/headerAvatar.svg';
import { useLogout } from 'hooks/auth/useLogout';
import { AdminResponse, AdminService } from 'clients/CoreService';
import { entities } from 'consts';
import { useSnackbarOnError } from 'hooks';
import { SentryDefaults } from 'index';

import { Container, AdminNameContainer } from './AvatarPopover.styles';
import { Settings } from '.';

export function AdminName({ admin, ...rest }: { admin: AdminResponse } & TypographyProps) {
	return (
		<AdminNameContainer {...rest}>
			{`${admin.firstName} ${admin.lastName}`.trim()}
			<br />
			<Typography variant={'body2'} component={'span'}>
				{admin.email}
			</Typography>
		</AdminNameContainer>
	);
}

const AvatarPopover = () => {
	const { t } = useTranslation('components', { keyPrefix: 'appComponents' });
	const [opened, setOpened] = React.useState<boolean>(false);
	const [settingsOpen, setSettingsOpen] = React.useState<boolean>(false);
	const avatarContainerRef = React.useRef<HTMLDivElement>(null);
	const logout = useLogout();

	const { data: admin } = useQuery(
		[entities.administrators, entities.administratorsMe],
		() => AdminService.getAdmin(),
		{
			onError: useSnackbarOnError(),
		},
	);

	useEffect(() => {
		if (admin) {
			SentryDefaults.user = {
				...SentryDefaults.user,
				email: admin.email,
				name: `${admin.firstName} ${admin.lastName}`.trim(),
			};
		}
	}, [admin]);

	function handleClick(): void {
		setOpened(!opened);
	}

	function handleClose(): void {
		setOpened(false);
	}

	return (
		<>
			<Container ref={avatarContainerRef} onClick={handleClick}>
				<AvatarIcon />
				{admin && <AdminName admin={admin} />}
			</Container>
			<Menu
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				anchorEl={avatarContainerRef.current}
				open={opened}
				onClose={handleClose}
				style={{ marginTop: '10px' }}
			>
				<MenuItem
					onClick={() => {
						setOpened(false);
						setSettingsOpen(true);
					}}
				>
					{t('avatarPopover.settings')}
				</MenuItem>
				<MenuItem onClick={logout}>{t('avatarPopover.logout')}</MenuItem>
			</Menu>
			<Settings open={settingsOpen} onClose={() => setSettingsOpen(false)} />
		</>
	);
};

export default AvatarPopover;
