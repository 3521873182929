import React, { useState } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export type PasswordTextFieldProps = {} & TextFieldProps;

export function PasswordTextField({ ...props }: PasswordTextFieldProps) {
	const [showPassword, setShowPassword] = useState<boolean>(false);

	return (
		<TextField
			variant={'outlined'}
			fullWidth
			type={showPassword ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<IconButton onClick={() => setShowPassword(s => !s)}>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
}
