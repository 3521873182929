import { Outlet } from 'react-router-dom';
import { ComponentProps } from 'react';

import {
	LayoutContainer,
	FormContainer,
	Container,
} from 'components/AuthWrapper/AuthWrapper.styles';
import { AppHeader, Footer } from 'containers/AppComponents';

type AuthContainerProps = {} & Omit<ComponentProps<'div'>, 'ref'>;

const AuthContainer = ({ ...rest }: AuthContainerProps) => (
	<Container>
		<AppHeader hideUser noHomeLink />
		<LayoutContainer>
			<FormContainer>
				<Outlet {...rest} />
			</FormContainer>
		</LayoutContainer>
		<Footer greyBackground />
	</Container>
);

export default AuthContainer;
