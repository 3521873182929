/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WebsocketEventName {
	MEETING_VIDEO_CHANGED = 'MeetingVideoChanged',
	MEETING_UPDATED = 'MeetingUpdated',
	PARTICIPANT_UPDATED = 'ParticipantUpdated',
	ROOM_STATE_UPDATED = 'RoomStateUpdated',
	POLL_UPDATED = 'PollUpdated',
	VOTE_UPDATED = 'VoteUpdated',
	ACTIVE_POLL_UPDATED = 'ActivePollUpdated',
	ACTIVE_VOTES_UPDATED = 'ActiveVotesUpdated',
	STREAM_STATE_UPDATED = 'StreamStateUpdated',
	CONNECT_JITSI_TO_STREAM_REQUEST = 'ConnectJitsiToStreamRequest',
	QUESTION_UPDATED = 'QuestionUpdated',
	CHAT_LIST_UPDATED = 'ChatListUpdated',
	ADMIN_REQUEST_CHANGE_SETTINGS = 'AdminRequestChangeSettings',
	PARTICIPANT_ROOM_TYPE_UPDATED = 'ParticipantRoomTypeUpdated',
	PARTICIPANT_VERIFICATION_UPDATED = 'ParticipantVerificationUpdated',
	SPEAKERS_LIST_UPDATED = 'SpeakersListUpdated',
	PARTICIPANT_IS_ALREADY_CONNECTED = 'ParticipantIsAlreadyConnected',
	PARTICIPANT_IS_NOT_CONNECTED = 'ParticipantIsNotConnected',
	FORCE_QUIT = 'ForceQuit',
	DOCUMENTS_UPDATED = 'DocumentsUpdated',
	ADMINS_HAS_LEFT_MEETING = 'AdminsHasLeftMeeting',
	ATTENDEE_ROLE_UPDATED = 'AttendeeRoleUpdated',
	CALLOUT_INFO_UPDATED = 'CalloutInfoUpdated',
	ATTENDENCY_INFO_UPDATED = 'AttendencyInfoUpdated',
	RAISED_HAND = 'RaiseHand',
}
