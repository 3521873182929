import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
`;

export const PasswordTypography = styled(Typography).attrs({
	variant: 'h6',
})`
	display: flex;
	align-items: center;
`;

export const PasswordLockWrapper = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50px;
	background: ${({ theme }) => theme.palette.background.default};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
`;

export const Title = styled(Typography).attrs({
	color: 'textPrimary',
	variant: 'h3',
})``;

export const ChangePasswordButton = styled(Button)`
	margin-top: 1rem;
`;

export const WEdit = styled(Edit)`
	margin-left: 0.5rem;
`;

export const WDialogActions = styled(DialogActions)`
	display: flex;
	justify-content: space-between;
`;

export const WDialogTitle = styled(DialogTitle)`
	padding: 0.5rem 0 0 0.5rem;
`;

export const WDialogContent = styled(DialogContent)`
	margin: 2rem 0 0;
	padding-bottom: 0;

	&.internal-gaps > * {
		margin-top: 1rem;
	}
`;

export const SettingsDialog = styled(Dialog)`
	.MuiDialog-paper {
		min-width: 30rem;
	}
`;
