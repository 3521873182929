import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

import { ReactComponent as AvatarIcon } from 'assets/icons/headerAvatar.svg';

import { ReactComponent as LogoIcon } from 'assets/icons/logo/motion.svg';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: ${({ theme }) => theme.palette.background.default};
`;

export const WAppBar = styled(AppBar)`
	position: relative;
	padding: 0.5rem 2rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const WLogo = styled(LogoIcon)`
	height: 100%;
`;

export const Content = styled.div`
	flex: 1;
	display: grid;
	grid-template-columns: min-content 1fr;
	grid-template-rows: 1fr min-content;
	grid-template-areas:
		'sidebar children'
		'sidebar footer';
`;

export const SideBarHolder = styled(Paper)`
	display: flex;
	flex-direction: column;
	padding: 1rem;

	.MuiList-root {
		display: flex;
		flex-direction: column;
		> *:not(:first-child) {
			margin-top: 0.5rem;
		}
	}
`;

export const ChildrenWrapper = styled.div`
	flex: 1;
	padding: 2rem;

	@media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		padding: 0.5rem;
	}
`;

export const RightSide = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

export const LanguageSelect = styled(Select)`
	.MuiOutlinedInput-notchedOutline {
		border: 0;
	}

	.MuiSelect-select {
		padding: 0;
	}

	.MuiSelect-select,
	.MuiSelect-icon {
		color: ${({ theme }) => theme.palette.primary.contrastText};
	}
`;

export const RoleSwitcherContainer = styled.div`
	display: flex;
	align-items: center;
	grid-gap: 0.5rem;
`;

export const WSwitch = styled(Switch)`
	width: 2.4rem;
	height: 1.2rem;
	padding: 0;

	& .MuiSwitch-switchBase {
		padding: 0;
		margin: 0.2rem;
	}

	& .MuiSwitch-thumb {
		height: 0.8rem;
		width: 0.8rem;
	}

	& .MuiSwitch-track {
		border-radius: 0.6rem;
	}

	& .Mui-checked {
		.MuiSwitch-thumb {
			background: ${({ theme }) => theme.palette.primary.main};
		}

		& + .MuiSwitch-track {
			background: ${({ theme }) => theme.palette.primary.contrastText};
			opacity: 1;
		}
	}
`;

export const RoleSwitcherTypography = styled(Typography).attrs({})`
	@media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
		display: none;
	}
`;

export const FooterContainer = styled.div<{
	greyBackground?: boolean;
}>`
	padding: 0.8rem;
	background: ${({ theme, greyBackground }) =>
		greyBackground ? theme.palette.background.default : 'transparent'};
	border-top: 1px solid ${({ theme }) => theme.palette.grey['50']};
`;

export const WAvatarIcon = styled(AvatarIcon)`
	rect {
		fill: ${({ theme }) => theme.palette.primary.main};
		fill-opacity: 1;
	}
`;

export const SidebarAvatarContainer = styled(Grid).attrs({
	container: true,
	direction: 'row',
	alignItems: 'center',
})`
	margin: 1rem;
	background: ${({ theme }) => theme.colors.sidebarAvatarBackground};
	width: calc(100% - 2rem);
	padding: 1rem 1.5rem;
	border-radius: 0.25rem;
`;
