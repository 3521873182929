import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';

import { Language } from 'i18n';
import { LanguageSelect } from './App.styles';

export default function LanguageSwitcher() {
	const { i18n } = useTranslation();

	return (
		<div>
			<LanguageSelect
				value={i18n.language as Language}
				onChange={e => i18n.changeLanguage(e.target.value as Language)}
			>
				<MenuItem value={Language.EN} color={'primary'}>
					English
				</MenuItem>
				<MenuItem value={Language.FR} color={'secondary'}>
					Français
				</MenuItem>
			</LanguageSelect>
		</div>
	);
}
