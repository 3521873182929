/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Timezone {
    CANADA_ATLANTIC = 'Canada/Atlantic',
    CANADA_CENTRAL = 'Canada/Central',
    CANADA_EASTERN = 'Canada/Eastern',
    CANADA_MOUNTAIN = 'Canada/Mountain',
    CANADA_NEWFOUNDLAND = 'Canada/Newfoundland',
    CANADA_PACIFIC = 'Canada/Pacific',
    CANADA_SASKATCHEWAN = 'Canada/Saskatchewan',
    CANADA_YUKON = 'Canada/Yukon',
    AMERICA_ANCHORAGE = 'America/Anchorage',
    AMERICA_LOS_ANGELES = 'America/Los_Angeles',
    AMERICA_TIJUANA = 'America/Tijuana',
    AMERICA_DENVER = 'America/Denver',
    AMERICA_CHIHUAHUA = 'America/Chihuahua',
    AMERICA_MAZATLAN = 'America/Mazatlan',
    AMERICA_PHOENIX = 'America/Phoenix',
    AMERICA_REGINA = 'America/Regina',
    AMERICA_TEGUCIGALPA = 'America/Tegucigalpa',
    AMERICA_CHICAGO = 'America/Chicago',
    AMERICA_MEXICO_CITY = 'America/Mexico_City',
    AMERICA_MONTERREY = 'America/Monterrey',
    AMERICA_NEW_YORK = 'America/New_York',
    AMERICA_BOGOTA = 'America/Bogota',
    AMERICA_LIMA = 'America/Lima',
    AMERICA_RIO_BRANCO = 'America/Rio_Branco',
    AMERICA_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
    AMERICA_CARACAS = 'America/Caracas',
    AMERICA_HALIFAX = 'America/Halifax',
    AMERICA_MANAUS = 'America/Manaus',
    AMERICA_SANTIAGO = 'America/Santiago',
    AMERICA_LA_PAZ = 'America/La_Paz',
    AMERICA_ST_JOHNS = 'America/St_Johns',
    AMERICA_ARGENTINA_BUENOS_AIRES = 'America/Argentina/Buenos_Aires',
    AMERICA_SAO_PAULO = 'America/Sao_Paulo',
    AMERICA_GODTHAB = 'America/Godthab',
    AMERICA_MONTEVIDE = 'America/Montevide',
    EUROPE_LONDON = 'Europe/London',
}