/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeetingVoiceBroadcastingProgressResponse } from '../models/MeetingVoiceBroadcastingProgressResponse';
import type { MeetingVoiceBroadcastingResponse } from '../models/MeetingVoiceBroadcastingResponse';
import type { SetVoiceBroadcastingRequest } from '../models/SetVoiceBroadcastingRequest';
import { request as __request } from '../core/request';

export class VoiceBroadcastingService {

    /**
     * @param meetingId
     * @returns MeetingVoiceBroadcastingResponse
     * @throws ApiError
     */
    public static async getVoiceBroadcasting(
        meetingId: number,
    ): Promise<MeetingVoiceBroadcastingResponse> {
        const result = await __request({
            method: 'GET',
            path: `/voice_broadcasting/${meetingId}/broadcasting/settings`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns MeetingVoiceBroadcastingResponse
     * @throws ApiError
     */
    public static async setVoiceBroadcasting(
        meetingId: number,
        requestBody: SetVoiceBroadcastingRequest,
    ): Promise<MeetingVoiceBroadcastingResponse> {
        const result = await __request({
            method: 'POST',
            path: `/voice_broadcasting/${meetingId}/broadcasting/settings`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns any
     * @throws ApiError
     */
    public static async cancelVoiceBroadcasting(
        meetingId: number,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/voice_broadcasting/${meetingId}/broadcasting/cancel`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingVoiceBroadcastingProgressResponse
     * @throws ApiError
     */
    public static async getVoiceBroadcastingProgress(
        meetingId: number,
    ): Promise<MeetingVoiceBroadcastingProgressResponse> {
        const result = await __request({
            method: 'GET',
            path: `/voice_broadcasting/${meetingId}/broadcasting/progress`,
        });
        return result.body;
    }

}