import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import { ReportDialogOptions } from '@sentry/browser/dist/helpers';

import './i18n';

export class ConsoleSettings {
	public static instance: ConsoleSettings = new ConsoleSettings();

	public socketLog = process.env.NODE_ENV === 'development';
	private constructor() {
		(global as any).turnOnSocketLog = () => {
			console.log('Everything for you, Sanya 😘');
			this.socketLog = true;
		};
		(global as any).turnOffSocketLog = () => {
			this.socketLog = false;
		};
	}
}

export const SentryDefaults: ReportDialogOptions = {};
Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN || '',
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.REACT_APP_ENV || '',
	enabled: false,

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
	beforeSend(event) {
		// Check if it is an exception, and if so, show the report dialog
		if (event.exception) {
			Sentry.showReportDialog({ eventId: event.event_id, ...SentryDefaults });
		}
		return event;
	},
});

ReactDOM.render(
	<React.StrictMode>
		<Sentry.ErrorBoundary>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Sentry.ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
