/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AmdResult {
    HUMAN_RESIDENCE = 'HumanResidence',
    HUMAN_BUSINESS = 'HumanBusiness',
    MACHINE = 'Machine',
    SILENCE = 'Silence',
    FAX_DETECTED = 'FaxDetected',
}