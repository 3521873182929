import { createGlobalStyle } from 'styled-components';
import { Theme } from '@mui/material/styles';
import './fonts.css';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    width: 100%;
    height: 100%;
	  font-size: 75%;
	  font-synthesis: none;
	  -moz-osx-font-smoothing: grayscale;
	  -webkit-font-smoothing: antialiased;
  }
	body {
    width: 100%;
		height: 100%;
		margin: 0;
  }
	#root {
		width: 100%;
		height: 100%;
	}
  .MuiCollapse-wrapper {
	  pointer-events: auto;
  }
  .MuiInputBase-input:-webkit-autofill { 
		// to cover all input
    -webkit-box-shadow: 0 0 0 1000px ${({ theme }) =>
			theme.palette.background.paper} inset !important;
  }
	.MuiDateTimePickerToolbar-separator {
		margin: 8px 4px 0 2px;
	}
  // .MuiDataGrid-root {
  //   box-shadow: ${({ theme }: { theme: Theme }) => theme.shadows[2]};
  //   border: none;
  // }
  // .MuiDataGrid-cell {
  //   outline: none !important;
  // }
`;
