/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentResponse } from '../models/DocumentResponse';
import type { MeetingDocumentResponse } from '../models/MeetingDocumentResponse';
import type { SignedPostUrlResponse } from '../models/SignedPostUrlResponse';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * @param filenames
     * @param meetingId
     * @returns SignedPostUrlResponse
     * @throws ApiError
     */
    public static async getSignedPostUrls(
        filenames: Array<string>,
        meetingId: number,
    ): Promise<SignedPostUrlResponse> {
        const result = await __request({
            method: 'GET',
            path: `/documents/signed-urls/post`,
            query: {
                'filenames': filenames,
                'meetingId': meetingId,
            },
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param requestBody
     * @returns DocumentResponse
     * @throws ApiError
     */
    public static async setMeetingDocument(
        meetingId: number,
        requestBody: string,
    ): Promise<DocumentResponse> {
        const result = await __request({
            method: 'POST',
            path: `/documents/meetings/${meetingId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @returns MeetingDocumentResponse
     * @throws ApiError
     */
    public static async getMeetingDocuments(
        meetingId: number,
    ): Promise<MeetingDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/documents/meetings/${meetingId}`,
        });
        return result.body;
    }

    /**
     * @param meetingId
     * @param documentId
     * @returns DocumentResponse
     * @throws ApiError
     */
    public static async deleteMeetingDocument(
        meetingId: number,
        documentId: number,
    ): Promise<DocumentResponse> {
        const result = await __request({
            method: 'DELETE',
            path: `/documents/meetings/${meetingId}`,
            query: {
                'documentId': documentId,
            },
        });
        return result.body;
    }

}