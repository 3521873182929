/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatResponse } from '../models/ChatResponse';
import type { CreateChatRequest } from '../models/CreateChatRequest';
import type { DataQueryOrder } from '../models/DataQueryOrder';
import type { DataQueryOrderBy } from '../models/DataQueryOrderBy';
import type { DataQueryPage } from '../models/DataQueryPage';
import type { DataQueryRowsPerPage } from '../models/DataQueryRowsPerPage';
import type { DataQuerySearchText } from '../models/DataQuerySearchText';
import type { Pagination_ChatParticipantResponse_ } from '../models/Pagination_ChatParticipantResponse_';
import type { UserTokenChatResponse } from '../models/UserTokenChatResponse';
import { request as __request } from '../core/request';

export class ChatService {

    /**
     * @param requestBody
     * @returns ChatResponse
     * @throws ApiError
     */
    public static async createChat(
        requestBody: CreateChatRequest,
    ): Promise<ChatResponse> {
        const result = await __request({
            method: 'POST',
            path: `/chats`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns UserTokenChatResponse
     * @throws ApiError
     */
    public static async getUserTokenChat(): Promise<UserTokenChatResponse> {
        const result = await __request({
            method: 'GET',
            path: `/chats`,
        });
        return result.body;
    }

    /**
     * @param rowsPerPage
     * @param page
     * @param orderBy
     * @param order
     * @param filters
     * @param searchText
     * @returns Pagination_ChatParticipantResponse_
     * @throws ApiError
     */
    public static async getChatParticipants(
        rowsPerPage: DataQueryRowsPerPage,
        page: DataQueryPage,
        orderBy?: DataQueryOrderBy,
        order?: DataQueryOrder,
        filters?: Array<string>,
        searchText?: DataQuerySearchText,
    ): Promise<Pagination_ChatParticipantResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/chats/participants`,
            query: {
                'rowsPerPage': rowsPerPage,
                'page': page,
                'orderBy': orderBy,
                'order': order,
                'filters': filters,
                'searchText': searchText,
            },
        });
        return result.body;
    }

}