/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganizationRequest } from '../models/CreateOrganizationRequest';
import type { OrganizationResponse } from '../models/OrganizationResponse';
import type { UpdateOrganizationRequest } from '../models/UpdateOrganizationRequest';
import { request as __request } from '../core/request';

export class OrganizationService {

    /**
     * @param organizationId
     * @returns OrganizationResponse
     * @throws ApiError
     */
    public static async getOrganization(
        organizationId: number,
    ): Promise<OrganizationResponse> {
        const result = await __request({
            method: 'GET',
            path: `/organizations/${organizationId}`,
        });
        return result.body;
    }

    /**
     * @param organizationId
     * @param requestBody
     * @returns OrganizationResponse
     * @throws ApiError
     */
    public static async updateOrganization(
        organizationId: number,
        requestBody: UpdateOrganizationRequest,
    ): Promise<OrganizationResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/organizations/${organizationId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param organizationId
     * @returns OrganizationResponse
     * @throws ApiError
     */
    public static async deleteOrganization(
        organizationId: number,
    ): Promise<OrganizationResponse> {
        const result = await __request({
            method: 'DELETE',
            path: `/organizations/${organizationId}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns OrganizationResponse
     * @throws ApiError
     */
    public static async createOrganization(
        requestBody: CreateOrganizationRequest,
    ): Promise<OrganizationResponse> {
        const result = await __request({
            method: 'POST',
            path: `/organizations`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param organizationId
     * @returns OrganizationResponse
     * @throws ApiError
     */
    public static async restoreOrganization(
        organizationId: number,
    ): Promise<OrganizationResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/organizations/${organizationId}/restore`,
        });
        return result.body;
    }

}