/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum QuestionStatus {
    UNSCREENED = 'Unscreened',
    SCREENING = 'Screening',
    SCREENED = 'Screened',
    ANSWERING = 'Answering',
    ANSWERED = 'Answered',
    REMOVED = 'Removed',
}