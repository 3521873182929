/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Order direction. Works together with 'orderBy'
 */
export enum DataQueryOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}