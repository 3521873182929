/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MagicLinkResponse } from '../models/MagicLinkResponse';
import type { MagicLinkTokenVerificationRequest } from '../models/MagicLinkTokenVerificationRequest';
import { request as __request } from '../core/request';

export class MagicLinkService {

    /**
     * Verify magic-link token, and get access and refresh tokens for an user if magic link token is valid.
     * @param requestBody
     * @returns MagicLinkResponse
     * @throws ApiError
     */
    public static async verifyToken(
        requestBody: MagicLinkTokenVerificationRequest,
    ): Promise<MagicLinkResponse> {
        const result = await __request({
            method: 'POST',
            path: `/magic-links/verification`,
            body: requestBody,
        });
        return result.body;
    }

}