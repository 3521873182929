/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MeetingStatus {
    CREATED = 'Created',
    ACTIVATED = 'Activated',
    STARTING = 'Starting',
    STARTED = 'Started',
    COMPLETED = 'Completed',
    CANCELED = 'Canceled',
    DELETED = 'Deleted',
}