import { useConfirmDialog } from 'components/ConfirmDialog';
import { useEffect } from 'react';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';

export function useOfflineDialog() {
	const confirmDialog = useConfirmDialog();
	useEffect(() => {
		const interval = setInterval(() => {
			if (!navigator.onLine) {
				confirmDialog({
					type: 'info',
					icon: DangerIcon,
					title: 'Network error',
					description: 'The network connection is lost',
					cancelText: 'Cancel',
					confirmText: 'OK',
				});
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [confirmDialog]);
}
