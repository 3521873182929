/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoomsStatusListResponse } from '../models/RoomsStatusListResponse';
import { request as __request } from '../core/request';

export class RoomService {

    /**
     * @returns RoomsStatusListResponse
     * @throws ApiError
     */
    public static async getRoomStates(): Promise<RoomsStatusListResponse> {
        const result = await __request({
            method: 'GET',
            path: `/rooms/states`,
        });
        return result.body;
    }

}