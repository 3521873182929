/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class JitsiService {

    /**
     * @param roomName
     * @param userName
     * @param userId
     * @returns string Ok
     * @throws ApiError
     */
    public static async generateJitsiJwt(
        roomName: string,
        userName: string,
        userId: string,
    ): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/jitsi/generate-jitsi-jwt`,
            query: {
                'roomName': roomName,
                'userName': userName,
                'userId': userId,
            },
        });
        return result.body;
    }

}